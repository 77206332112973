<template>
  <div>
    <Header />
    <div class="h-24"></div>
    <div class="container p-4">
      <div class="px-4 flex-grow flex mt-20">
        <div class="-mx-6 flex justify-center flex-wrap items-center flex-grow">
          <div class="px-2 w-1/2">
            <div class="">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <b-field
                label="Email"
                :type="$store.getters.hasErrors('email').type"
                :message="$store.getters.hasErrors('email').message"
              >
                <b-input v-model="email"></b-input>
              </b-field>
              <b-field
                label="New Password"
                :type="$store.getters.hasErrors('password').type"
                :message="$store.getters.hasErrors('password').message"
              >
                <b-input v-model="password" type="password"></b-input>
              </b-field>
              <div class="flex items-center justify-end">
                <b-button
                  type="is-gold"
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  expanded
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/client/Header";
import store from "@/store";
export default {
  title: () => {
    return `My Account - ${process.env.VUE_APP_TITLE}`;
  },
  data: () => ({
    name: "",
    email: "",
    password: ""
  }),
  components: {
    Header
  },
  computed: {},
  methods: {
    onSave() {
      store.dispatch("saveAccountForm", {
        id: store.getters.activeUser.id,
        name: this.name,
        email: this.email,
        password: this.password || undefined
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(res => {
        next(vm => {
          vm.name = res.data.name;
          vm.email = res.data.email;
        });
      });
    }
  }
};
</script>
